import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: 'https://farms.zonoswap.com/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        href: '/swap',
      },
      {
        label: 'Liquidity',
        href: '/pool',
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: 'https://farms.zonoswap.com/farms',
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: 'https://farms.zonoswap.com/pools',
  },
  {
    label: 'Prediction Game',
    icon: 'GameIcon',
    href: 'https://farms.zonoswap.com/game',
  },
  {
    label: 'NFT Marketplace',
    icon: 'NftIcon',
    href: 'https://nft.zonoswap.com/',
  },
   {
    label: 'Smart Contract Audit',
    icon: 'TicketIcon',
    href: 'https://github.com/solidproof/smart-contract-audits/blob/main/SmartContract_Audit_Solidproof_ZONOSwap.pdf',
  },
    {
    label: 'KYC',
    icon: 'IfoIcon',
    href: 'https://github.com/solidproof/kyc-certificates/blob/main/KYC_Certificate_Zono_Swap.png',
  },
  // {
  //   label: 'Teams & Profile',
  //   icon: 'GroupsIcon',
  //   calloutClass: 'rainbow',
  //   items: [
  //     {
  //       label: 'Leaderboard',
  //       href: '/teams',
  //     },
  //     {
  //       label: 'Task Center',
  //       href: '/profile/tasks',
  //     },
  //     {
  //       label: 'Your Profile',
  //       href: '/profile',
  //     },
  //   ],
//  },
    {
      label: 'More',
      icon: 'MoreIcon',
      items: [
        
        {
          label: 'Github',
          href: 'https://github.com/zono-swap',
        },
        {
          label: 'Docs',
          href: 'https://zonoswap.gitbook.io/zonoswap/',
        },
        {
          label: 'Blog',
          href: 'https://medium.com/@zonoswap',
        },
      ],
    
  },
]

export default config
